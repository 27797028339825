<template>
    <div>
        <div class="container policy" v-html="t('policy')"></div>
        <p class="d-flex justify-content-center mb-5">
            <router-link to="/registrate"> <b-icon-arrow-left-short /> {{ t('back') }}</router-link>
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style>
    .policy {
        max-width: 790px !important;
        padding-top: 70px !important;
    }
</style>